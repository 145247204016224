import { Skeleton, TableCell, TableRow } from "@mui/material"


const LoaderUsersList = () => {
    const lines = 5 ;
    return (
        <>
            {Array(lines).fill(0).map((_,i) => (
                <TableRow key={i}>
                    <TableCell>
                        <Skeleton variant="text" />
                    </TableCell>
                    <TableCell>
                        <Skeleton variant="text" />
                    </TableCell>
                    <TableCell>
                        <Skeleton variant="text" />
                    </TableCell>
                    <TableCell>
                        <Skeleton variant="text" />
                    </TableCell>
                </TableRow>
            ))}

        </>
        
    )
}

export default LoaderUsersList;