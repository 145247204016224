import { Divider, Grid, Typography } from "@mui/material";
import React from "react"


const Footer = () => {


    return (
        <Grid container alignItems={'center'} style={{ paddingTop : 15 , paddingBottom : 15}} className="footerContainer" >
            <Grid item xs={12} style={{ paddingBottom : 15 }} >
                <Divider/>
            </Grid>
     
            <Grid item xs={12}>
               <Typography variant='caption' display={'block'} width={'100%'} textAlign={'center'}>
                    © 2024 Clarity Lens | All Rights Reserved
               </Typography>
            </Grid>
   
        </Grid>
    )

}
export default Footer;