import { Grid, Typography } from "@mui/material";
import DefaultLayout from "../layouts/Default";

const HomePage = () => {
    return (
        <DefaultLayout>
            <Grid container alignItems={"center"} justifyContent={"center"} className="defaultSection" >
                <Typography fontWeight={"bold"} variant="h2" textAlign={"center"} > Get Work Done, Peacefully! </Typography>
            </Grid>
        </DefaultLayout>
    )
}
export default HomePage;