import { prvAPI, pubAPI } from "..";
import { isUserLoggedin } from "../../state/slices/userLoggedIn";
import Cookies from 'universal-cookie';
import { store } from "../../state/store";
import { redirect } from "react-router-dom";


const userAcceptInvite = async ( inviteToken : String  ) => {
    const resp  = await pubAPI.post('/auth/invite/accept', { inviteToken });
    if(resp.data.status === 'Success'){
        setTimeout(() => {
            redirect("/login");
        }, 3000);
        return resp.data ;
    }
    
    return resp.data ;
}


const userLoggout = async () => {
    const resp  = await prvAPI.post('/auth/logout');
    
    if(resp.data.status === 'Success'){
        forceLogout();
        return true ;
    }
    
    return false ;
}

const forceLogout = async ( ) =>{  
    const cookies = new Cookies();
    store.dispatch(isUserLoggedin(false));
    cookies.remove('access_token');
    cookies.remove('refresh_token');
}

export {
    userLoggout,
    forceLogout,
    userAcceptInvite
};