import { Button, Divider, Grid, Link, Typography } from "@mui/material";
import '../../styles/header.scss';
import React from "react"
import ProfileMenu from "../general/ProfileMenu";
import { DarkModeSwitch } from 'react-toggle-dark-mode';
import type { RootState } from '../../state/store';
import { useDispatch, useSelector } from "react-redux";
import {toggleDarkMode} from "../../state/slices/toggleDarkMode";

const HeaderBar = () => {
    const isDarkModeEnabled = useSelector((state: RootState) => state.darkMode.isEnabled)
    const isUserLoggedIn = useSelector((state : RootState) => state.user.isUserLoggedin);
    const dispatch = useDispatch();

    return (
        <Grid container alignItems={'center'} style={{ paddingTop : 15 , paddingBottom : 15}}>
            <Grid item xs={3}>
                <Link href={isUserLoggedIn ? "/app" :"/" } variant={'button'} underline="none"  >
                    <Typography>Freny</Typography>
                </Link>
            </Grid>
            <Grid item xs={7} flexGrow="space-around" flex={1} >
                
            </Grid>
            <Grid item xs={2} alignItems={'center'} justifyContent={'space-between'} display={'flex'} >
                
                {!isUserLoggedIn && <Link href="/login"className="menuLink" underline="none">
                    <Button variant={'contained'} > Sign in</Button>            
                </Link>}
                {isUserLoggedIn && <ProfileMenu />}
                <DarkModeSwitch
                    checked={isDarkModeEnabled}
                    onChange={
                        () => dispatch(toggleDarkMode())
                    }
                    size={32}
                />
            </Grid>
            <Grid item xs={12} className="inputRow" >
                <Divider />
            </Grid>
        </Grid>
    )

}
export default HeaderBar;