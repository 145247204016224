import { Grid, Skeleton } from "@mui/material"
const LoaderProjectList = () => {
    return (
        <Grid container>
            <Grid item xs={12}>
                <Skeleton variant={'rectangular'} width={150} height={40} />
                <Skeleton variant={'rectangular'} width={150} height={40} style={{ marginTop : 15 }}  />
                <Skeleton variant={'rectangular'} width={150} height={40} style={{ marginTop : 15 }}  />
                <Skeleton variant={'rectangular'} width={150} height={40} style={{ marginTop : 15 }}  />
            </Grid>
        </Grid>
    )
}

export default LoaderProjectList;