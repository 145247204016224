import { Grid, Tab, Tabs, Typography } from "@mui/material"
import { gql, useQuery } from '@apollo/client';
import { useDispatch, useSelector } from "react-redux";
import { RootState } from "../../../state/store";
import LoaderProjectList from "./skeletions/LoaderProjectList";
import { updateSelectedProject } from "../../../state/slices/activeIds";
import CreateProject from "../../projects/CreateProject";
import { useEffect } from "react";

interface workspaceObj {
    id : String
    title: String
}
const GQL_QUERY = gql`
    query Projects($workspaceId: ProjectParams) {
        projects(workspaceId: $workspaceId) {
            results {
                title
                id
            }
        }
    }
`;

const ProjectList = () => {
    const workspaceId = useSelector((state : RootState) => state.activeId.selectedWorkspace);
    const projectId = useSelector((state : RootState) => state.activeId.selectedProject);
    const dispatch = useDispatch();

    const { loading, error, data, refetch} = useQuery(GQL_QUERY,{
        variables : {
            workspaceId: {
                workspaceId: workspaceId
            }
        }
    });
  
    useEffect(() =>{ 
        if(!loading)
            dispatch(updateSelectedProject(data?.projects.results.length > 0 ? data.projects.results[0].id :'' ))
    },[data, loading, dispatch]);

    if (loading) return  <LoaderProjectList/>;
    if (error) return  (<Typography> Error! {error.message}</Typography>);
  
  

    const onTabChange = (event: React.SyntheticEvent, newValue: number) => {   
        dispatch(updateSelectedProject(newValue));
    };

    return (
        <Grid container>
            <Grid item xs={12} display={'flex'} justifyContent={'center'} style={{ paddingTop : 15 , paddingBottom : 15 }} >
                <Typography variant="button" textAlign={'center'}>My Projects </Typography>
            </Grid>
            <Grid item xs={12}>
                <Tabs 
                    value={projectId}
                    orientation="vertical"
                    onChange={onTabChange}
                >
                    {data?.projects.results.map((result : workspaceObj) => <Tab label={result.title} value={result.id}  />)}
                </Tabs>
            </Grid>
            <Grid item xs={12} className="inputRow">
                <CreateProject openbyDefault={false} onSuccess={() => refetch()} />
            </Grid>

        </Grid>
    )
}

export default ProjectList;