import { useSelector } from "react-redux"
import { Navigate, createBrowserRouter } from "react-router-dom"
import { RootState } from "../state/store";
import HomePage from "../pages/Home";
import LoginPage from "../pages/auth/Login";
import DashboardPage from "../pages/app/dashboard";
import { ReactNode } from "react";
import WorkspaceManageTeamPage from "../pages/app/settings/team";
import InvitePage from "../pages/auth/Invite";
import TasksPage from "../pages/app/tasks";
import JobsPage from "../pages/app/tasks/jobs";
import MeTasksPage from "../pages/app/tasks/me";
import SettingsPage from "../pages/app/settings";

interface Props {
    children: ReactNode
    // any props that come into the component
}
export const AuthRoute = ({ children } : Props) => {
  const isUserLoggedIn = useSelector((state : RootState) => state.user.isUserLoggedin);
  if (isUserLoggedIn) {
    return <Navigate to="/app" />;
  }
  return <>{children}</>;
};

export const ProtectedRoute = ({ children } : Props) => {
    const isUserLoggedIn = useSelector((state : RootState) => state.user.isUserLoggedin);
    if (!isUserLoggedIn) {
      return <Navigate to="/" />;
    }
    return <>{children}</>;
};

const router = createBrowserRouter([
    {
      path: "/",
      element: <HomePage /> ,
    },
    {
      path: "/login",
      element: <AuthRoute>
        <LoginPage />
      </AuthRoute> ,
    },
    {
      path: "/invite/:inviteId",
      element: <InvitePage/>,
    },
    {
      path: "/app",
      element: 
        <ProtectedRoute>
          <DashboardPage /> 
        </ProtectedRoute>
      ,
    },
    {
      path: "/app/workspace/tasks",
      element: 
        <ProtectedRoute>
          <TasksPage /> 
        </ProtectedRoute>
      ,
    },
    {
      path: "/app/workspace/jobs",
      element: 
        <ProtectedRoute>
          <JobsPage /> 
        </ProtectedRoute>
      ,
    },
    {
      path: "/app/workspace/me",
      element: 
        <ProtectedRoute>
          <MeTasksPage /> 
        </ProtectedRoute>
      ,
    },
    {
      path: "/app/workspace/team",
      element: 
        <ProtectedRoute>
          <WorkspaceManageTeamPage /> 
        </ProtectedRoute>
      ,
    },
    {
      path: "/app/workspace/settings",
      element: 
        <ProtectedRoute>
          <SettingsPage /> 
        </ProtectedRoute>
      ,
    },
]);

export default router ;