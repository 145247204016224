import { Grid, Tab, Tabs, Typography } from "@mui/material"
import CreateNewWorkspace from "../workspace/CreateWorkspace";
import { gql, useQuery } from '@apollo/client';
import LoaderApplicationSecondaryHeader from "./skeletions/LoaderApplicationSecondaryHeader";
import { useDispatch, useSelector } from "react-redux";
import { RootState } from "../../state/store";
import { updateSelectedWorkspace } from "../../state/slices/activeIds";

interface workspaceObj {
    id : String
    title: String
}
const GQL_QUERY = gql`
    query GetWorkspaces {
        workspaces {
            results {
                id
                title
            }
        }
    }
`;

const ApplicationSecondaryHeader = () => {
    const { loading, error, data, refetch} = useQuery(GQL_QUERY);
    const dispatch = useDispatch();
    const selectedWorkspaceId = useSelector((state: RootState) => state.activeId.selectedWorkspace)

    if (loading) return <LoaderApplicationSecondaryHeader /> ;
    if (error) return  (<Typography> Error! {error.message}</Typography>);
    
    const onTabChange = (event: React.SyntheticEvent, newValue: number) => {   
        dispatch(updateSelectedWorkspace(newValue));
    };
    
    return (
        <Grid container>

            <Grid item xs={10}>
                <Tabs
                    value={selectedWorkspaceId}
                    onChange={onTabChange}
                >
                    {data.workspaces.results.map((result : workspaceObj) => <Tab label={result.title} value={result.id}  />)}
                </Tabs>
            </Grid>

            <Grid item xs={2} display={'flex'} alignItems={'flex-end'} alignContent={'flex-end'} justifyContent={'flex-end'} >
                <CreateNewWorkspace  openbyDefault={(data.workspaces.results.length) ? false : true } onSuccess={() => refetch()} />
            </Grid>
        
        </Grid>
    )
}

export default ApplicationSecondaryHeader;