import DefaultLayout from "../../layouts/Default";
import SignUp from "../../components/auth/SignUp";
import { Grid } from "@mui/material";

const LoginPage = () => {
    return (
        <DefaultLayout>
            <Grid container className="defaultSection" justifyContent={"center"} alignItems={"center"} > 

                <Grid item xs={12} sm={6}>
                    <SignUp />
                </Grid>
                <Grid item xs={12} sm={6}>
                </Grid>
            </Grid>
        </DefaultLayout>
    )
}
export default LoginPage;