
const APIConsts = {
    BACKEND_PORT : 4000, 
    BACKEND_HOST : 'localhost', 
    BACKEND_BASE_URL : 'http://localhost:4000/api/v1/'
}

const ThirdParty = {
    OTPLESS_ProjectID : 'E3C06V6YMNHSVG8EJYIUS1EODO2CA3CH', 
    AUTH0_DOMAIN : "dev-fqf8qixnmsmqgugh.us.auth0.com",
    AUTH0_CLIENT_ID : "NgFBaf37afpb6L9wUH8SiL66V7dizetg"
}

const CountryList = [
    {
        label : '+91', 
        prefix : 91
    }
];

export { APIConsts, ThirdParty , CountryList};