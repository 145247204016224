import { createSlice } from '@reduxjs/toolkit'

export interface UserLoggedInState {
  isUserLoggedin: boolean
}

const initialState: UserLoggedInState = {
  isUserLoggedin: false,
}

export const userLoggedInSlice = createSlice({
  name: 'user',
  initialState,
  reducers: {
    isUserLoggedin: (state, action) => {
      state.isUserLoggedin = action.payload;
    }
  },
})

// Action creators are generated for each case reducer function
export const { isUserLoggedin } = userLoggedInSlice.actions

export default userLoggedInSlice.reducer