import DefaultLayout from "../../layouts/Default";
import { Alert, CircularProgress, Grid, Typography } from "@mui/material";
import { useParams } from "react-router-dom";
import { useEffect, useState } from "react";
import { userAcceptInvite } from "../../functions/auth";
import { AlertColor } from '@mui/material/Alert';


const InvitePage = () => {
    
    const [errorMessage,setErrorMessage] = useState();
    const [messageType, setMessageType] = useState<AlertColor | undefined>('error');
    const { inviteId } = useParams();

    useEffect( () => {

        if(inviteId){
            userAcceptInvite(inviteId).then(data => {
                if(data.status === 'Success'){
                    setMessageType('success')
                    setErrorMessage(data.message);
                }else{
                    setMessageType('error')
                    setErrorMessage(data.message);
                }
            })
        }

    },[inviteId])

    return (
        <DefaultLayout>
            <Grid container className="defaultSection" justifyContent={"center"} alignItems={"center"} > 

                <Grid item xs={12} sm={6}
                    display={'flex'}
                    alignItems={'center'}
                    justifyContent={'center'}
                >
                    <Typography variant="h6" > 
                        You are invited 
                    </Typography>
                </Grid>
                <Grid item xs={12} sm={6}
                    display={'flex'}
                    alignItems={'center'}
                    justifyContent={'center'}
                >
                    {!errorMessage ? 
                        <CircularProgress /> : 
                        <Alert severity={messageType} >{errorMessage}</Alert>
                    }
                </Grid>
            </Grid>
        </DefaultLayout>
    )
}
export default InvitePage;