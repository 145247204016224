import { Grid, Skeleton } from "@mui/material"
const LoaderApplicationSecondaryHeader = () => {
    return (
        <Grid container>
            <Grid item xs={10} display={'flex'} flexDirection={'row'}>
                <Skeleton variant={'rectangular'} width={150} height={40} />
                <Skeleton variant={'rectangular'} width={150} height={40} style={{ marginLeft : 15 }}  />
            </Grid>

            <Grid item xs={2} display={'flex'} alignItems={'flex-end'} alignContent={'flex-end'} justifyContent={'flex-end'} >
                <Skeleton variant={'rectangular'} height={40} width={150}  />
            </Grid>
        </Grid>
    )
}

export default LoaderApplicationSecondaryHeader;