import { gql, useMutation } from "@apollo/client";
import { Alert, Autocomplete, Box, Button, Grid, LinearProgress, Modal, TextField, Typography } from "@mui/material"
import { SyntheticEvent, useState } from "react"
import { useSelector } from "react-redux";
import { RootState } from "../../state/store";
import { CountryList } from "../../constants";

interface Props {
    openbyDefault : boolean, 
    onSuccess : Function
}
const style = {
    position: 'absolute' as 'absolute',
    top: '50%',
    left: '50%',
    transform: 'translate(-50%, -50%)',
    width: 400,
    bgcolor: 'background.paper',
    border: '2px solid #000',
    boxShadow: 24,
    p: 4,
};
const ADD_WORKSPACE = gql`
    mutation SendWorkspaceUserInvite($workspaceInviteUser: WorkspaceUserInviteInput) {
        sendWorkspaceUserInvite(workspaceInviteUser: $workspaceInviteUser) {
            phone
            phoneCountryCode
            id
        }
    }
`;

const AddWorkspaceUser = ({ openbyDefault = true , onSuccess } : Props) => {
    const [addWorkspace, { loading, error }] = useMutation(ADD_WORKSPACE);

    const workspaceId = useSelector((state : RootState) => state.activeId.selectedWorkspace);
    const [mob, setmob] = useState<number>();
    const [country,setCountry] = useState<Number>(91);
    const [openModal,setOpenModal] = useState<boolean>(openbyDefault);
    const [errorMessage, setErrorMessage] = useState('');
    const [fname, setFname] = useState('');
    const [lname, setLname] = useState('');

    
    if (error) return <Typography>Submission error! {error.message}</Typography>;

   
    const numberInputOnWheelPreventChange = (e : SyntheticEvent) => {
        // Prevent the input value change
        (e.target as HTMLInputElement).blur()
    
        // Prevent the page/container scrolling
        e.stopPropagation()
        setTimeout(() => {
            (e.target as HTMLInputElement).focus()
        }, 0)
    }

      
    const handleAddNewWorkspace = () => {
        addWorkspace({
            variables : {
                workspaceInviteUser: {
                    phone: mob?.toString(),
                    phoneCountryCode: country?.toString(),
                    workspaceId: workspaceId, 
                    fname,
                    lname
                }
            }
        })
        .catch((error) => setErrorMessage(error.message))
        .then(() => { 
            setOpenModal(false)
            onSuccess();
        });
    }
    return (
        <>
            <Modal open={openModal} onClose={() => setOpenModal(false)} >
                <Grid sx={style}>
                    {errorMessage &&
                        <Grid item xs={12} className="inputRow">
                            <Alert severity={'error'} >
                                {errorMessage}
                            </Alert>
                        </Grid>
                    }
                    <Grid item xs={12} className="inputRow">
                        <Typography variant="h6" > Invite User </Typography>
                    </Grid>
                    <Grid item xs={12} className="inputRow">
                        <Grid container justifyContent={'space-between'} >
                            <Grid item xs={5.5}>
                                <TextField 
                                    required 
                                    label='First Name' 
                                    fullWidth
                                    value={fname}
                                    onChange={(event: React.ChangeEvent<HTMLTextAreaElement | HTMLInputElement> ) => {
                                        setFname(event?.target?.value)
                                    }}
                                />
                            </Grid>
                            <Grid item xs={5.5}>
                                <TextField 
                                    required 
                                    label='Last Name' 
                                    fullWidth
                                    value={lname}
                                    onChange={(event: React.ChangeEvent<HTMLTextAreaElement | HTMLInputElement> ) => {
                                        setLname(event?.target?.value)
                                    }}
                                />
                            </Grid>
                        </Grid>
                    </Grid>
                    <Grid item xs={12} className="inputRow" >
                        <Box sx={{ display: 'flex', alignItems: 'flex-end' }}>
                            <Autocomplete
                                disablePortal
                                id="combo-box-demo"
                                defaultValue={CountryList?.length > 0 ? CountryList[0] : null }
                                onChange={(e,val) => {
                                    if(val)
                                        setCountry(val.prefix)
                                }}
                                options={CountryList}
                                renderInput={(params) => <TextField required {...params} label="Country" fullWidth />}
                            />
                            <TextField required 
                                onChange={(event: React.ChangeEvent<HTMLTextAreaElement | HTMLInputElement> ) => {
                                    setmob(parseInt(event?.target?.value))
                                }}
                                label='Mobile No' 
                                type="number" 
                                onWheel={numberInputOnWheelPreventChange}
                                fullWidth 
                                value={mob}
                            >    
                            </TextField>
                        </Box>
                    </Grid>
                    <Grid item xs={12} >
                        {loading &&  <LinearProgress />}
                    </Grid>
                    <Grid item xs={12} className="inputRow">
                        <Button fullWidth variant="contained" onClick={() => handleAddNewWorkspace()} > Create </Button>
                    </Grid>
                </Grid>
            </Modal>

            <Button 
                variant={'contained'} onClick={() => setOpenModal(true)} >
                Invite User
            </Button>
        
        </>
    )
}

export default AddWorkspaceUser;