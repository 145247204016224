import { Grid, Typography } from "@mui/material";
import AppLayout from "../../../layouts/Application";

const JobsPage = () => {
    return (
        <AppLayout>
            <Grid container  >
                <Grid item xs={10} className="defaultSection">
                    <Typography fontWeight={"bold"} variant="h2" textAlign={"center"} > My Jobs   </Typography>
                </Grid>
                <Grid item xs={2}
                    alignItems={"center"} justifyContent={"center"} 
                >
                </Grid>
            </Grid>
        </AppLayout>       
    )
}


export default JobsPage;