import { createSlice } from '@reduxjs/toolkit'

export interface DarkModeState {
  isEnabled: boolean
}

const initialState: DarkModeState = {
    isEnabled: false,
}

export const darkModeSlice = createSlice({
  name: 'darkMode',
  initialState,
  reducers: {
    toggleDarkMode: (state) => {
      state.isEnabled = !state.isEnabled
    }
  },
})

// Action creators are generated for each case reducer function
export const { toggleDarkMode } = darkModeSlice.actions

export default darkModeSlice.reducer