import './SignUp.scss';
import { Alert, Autocomplete,Box, Grid, TextField, Typography  } from "@mui/material";
import ActionButton from '../general/ActionButton';
import { SyntheticEvent, useState } from 'react';
import { CountryList } from '../../constants';
import { pubAPI } from '../../functions';
import LinearProgress from '@mui/material/LinearProgress';
import Cookies from 'universal-cookie';
import { useDispatch } from 'react-redux';
import { isUserLoggedin } from '../../state/slices/userLoggedIn';

const SignUp = () => {
    const [step, setStep] = useState(1);
    const [mob, setmob] = useState<number>();
    const [otp, setOtp] = useState<number>();
    const [country,setCountry] = useState<Number>(91);
    const [loading,setLoading] = useState<Boolean>(false);
    const [requestId,setRequestId] = useState<String>();
    const [errorMessage,setErrorMessage] = useState<String>();
    const dispatch = useDispatch();


    const cookies = new Cookies();

    const numberInputOnWheelPreventChange = (e : SyntheticEvent) => {
        // Prevent the input value change
        (e.target as HTMLInputElement).blur()
    
        // Prevent the page/container scrolling
        e.stopPropagation()
        setTimeout(() => {
            (e.target as HTMLInputElement).focus()
        }, 0)
    }

    const sendLoginOTP = async () => {
        setLoading(true);
        const resp = await pubAPI.post('/auth',{
            phone : mob, 
            phoneCountryCode : country
        })


        if(resp?.data?.status === 'Success'){
            setRequestId(resp.data.data.requestId);
            setStep(2);
        }
        setLoading(false);        
    }

    const verifyOTP = async () => {
        setLoading(true);
        const resp = await pubAPI.post('/auth/verify',{
            requestId, 
            otp
        })

        if(resp?.data?.status === 'Success'){
            const {accessToken, refreshToken } = resp.data.data;
       
            // Set the access token as an HTTP-only cookie
            await cookies.set('access_token', accessToken, {
                path: '/',
                // httpOnly: true
            });
            // Set the access token as an HTTP-only cookie
            await cookies.set('refresh_token', refreshToken, {
                path: '/',
                // httpOnly: true
            });
            dispatch(isUserLoggedin(true));

        }else{
            setErrorMessage(resp.data.message)
            setTimeout(() => setErrorMessage(''),5000);
        }
        setLoading(false);        
    }

    return (
        <Box>
            <Grid container className="singupContainer">
                <Grid item xs={12} className="inputRow" >
                    <Typography variant="h6" > Sign In to continue </Typography>
                </Grid>
                {errorMessage && 
                    <Alert severity="error">
                        {errorMessage}
                    </Alert>
                }

                {step === 1 && <Grid item xs={12} className="inputRow" >

                    <Box sx={{ display: 'flex', alignItems: 'flex-end' }}>
                        <Autocomplete
                            disablePortal
                            id="combo-box-demo"
                            defaultValue={CountryList?.length > 0 ? CountryList[0] : null }
                            onChange={(e,val) => {
                                if(val)
                                    setCountry(val.prefix)
                            }}
                            options={CountryList}
                            renderInput={(params) => <TextField required {...params} label="Country" fullWidth />}
                        />
                        <TextField required 
                            onChange={(event: React.ChangeEvent<HTMLTextAreaElement | HTMLInputElement> ) => {
                                setmob(parseInt(event?.target?.value))
                            }}
                            label='Mobile No' 
                            type="number" 
                            fullWidth 
                            onWheel={numberInputOnWheelPreventChange}
                            value={mob}
                        >    
                        </TextField>
                    </Box>
                    
                </Grid>}
                {step === 2 && <Grid item xs={12} className="inputRow" >
                    <TextField required 
                        onChange={(event: React.ChangeEvent<HTMLTextAreaElement | HTMLInputElement> ) => {
                            setOtp(parseInt(event?.target?.value))
                        }}
                        label='Enter OTP' 
                        type="number" 
                        helperText={`OTP Sent to ${mob}`}
                        fullWidth 
                        value={otp}
                        onWheel={numberInputOnWheelPreventChange}
                    />    
                </Grid>}

                <Grid item xs={12}>
                    {loading && <LinearProgress  variant={'query'}   />}
                </Grid>
                <Grid item xs={12} className="inputRow" >

                    <ActionButton  
                        type="submit" 
                        fullWidth 
                        variant="contained" 
                        onClick={() => step === 1 ? sendLoginOTP() : verifyOTP() }
                        disabled={
                            
                            step === 1 ? (( mob?.toString()?.length === 10 && country) ? false : true ) : ((otp?.toString().length === 4) ? false : true )
                        
                        }
                    > 
                        {step === 1 ? 'Continue' : 'Sign In'} 
                    </ActionButton>
                </Grid>

            </Grid>
        </Box>
    )
}

export default SignUp;