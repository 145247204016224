import { Grid, Tab, Tabs } from "@mui/material";


function samePageLinkNavigation(
    event: any,
  ) {
    if(event?.target?.href === window.location.href){
      return true;
    }
    return false;
  }

interface LinkTabProps {
    value : string;
    label?: string;
    href?: string;
}

function LinkTab(props: LinkTabProps) {
    return (
      <Tab
        component="a"
        onClick={(event: React.MouseEvent<HTMLAnchorElement, MouseEvent>) => {
          // Routing libraries handle this, you can remove the onClick handle when using them.
          if (samePageLinkNavigation(event)) {
            event.preventDefault();
          }
        }}
        {...props}
      />
    );
}
  
const ManageWorkspaceBar = () => {
    
    return (
        <Grid container>

            <Grid item xs={12}>
                <Tabs
                  value={window.location.pathname}
                >
                    <LinkTab label='Overview' value='/app'  href='/app' />
                    <LinkTab label='Tasks' value='/app/workspace/tasks'  href='/app/workspace/tasks' />
                    <LinkTab label='Me' value='/app/workspace/me'  href='/app/workspace/me' />
                    <LinkTab label='Jobs' value='/app/workspace/jobs' href='/app/workspace/jobs' />
                    <LinkTab label='Team' value='/app/workspace/team' href='/app/workspace/team' />
                    <LinkTab label='Settings' value='/app/workspace/settings' href='/app/workspace/settings' />
                </Tabs>
            </Grid>
    
        </Grid>
    )
}

export default ManageWorkspaceBar;