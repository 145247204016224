import axios from "axios";
import { APIConsts } from "../constants";

const pubAPI = axios.create({
    baseURL : APIConsts.BACKEND_BASE_URL
});

const prvAPI = axios.create({
    baseURL : APIConsts.BACKEND_BASE_URL, 
    withCredentials: true 
})

export {
    pubAPI, 
    prvAPI
};