import { gql, useMutation } from "@apollo/client";
import { Alert, Button, Grid, LinearProgress, Modal, TextField, Typography } from "@mui/material"
import { useState } from "react"

interface Props {
    openbyDefault : boolean, 
    onSuccess : Function
}
const style = {
    position: 'absolute' as 'absolute',
    top: '50%',
    left: '50%',
    transform: 'translate(-50%, -50%)',
    width: 400,
    bgcolor: 'background.paper',
    border: '2px solid #000',
    boxShadow: 24,
    p: 4,
};
const ADD_WORKSPACE = gql`
    mutation CreateWorkspace($workspace: WorkspaceCreateInput!) {
        createWorkspace(workspace: $workspace) {
            title
        }
    }
`;

const CreateNewWorkspace = ({ openbyDefault = true , onSuccess } : Props) => {
    const [addWorkspace, { loading, error }] = useMutation(ADD_WORKSPACE);

  
    const [openModal,setOpenModal] = useState<boolean>(openbyDefault);
    const [workspaceName, setworkspaceName] = useState('');
    const [errorMessage, setErrorMessage] = useState('');

    
    if (error) return <Typography>Submission error! {error.message}</Typography>;

    const handleAddNewWorkspace = () => {
        addWorkspace({
            variables : {
                workspace : {
                    title : workspaceName
                }
            }
        })
        .catch((error) => setErrorMessage(error.message))
        .then(() => { 
            setOpenModal(false)
            onSuccess();
        });
    }
    return (
        <>
            <Modal open={openModal} onClose={() => setOpenModal(false)} >
                <Grid sx={style}>
                    {errorMessage &&
                        <Grid item xs={12} className="inputRow">
                            <Alert severity={'error'} >
                                {errorMessage}
                            </Alert>
                        </Grid>
                    }
                    <Grid item xs={12} className="inputRow">
                        <Typography variant="h6" > Create Workspace </Typography>
                    </Grid>
                    <Grid item xs={12} className="inputRow" >
                        <TextField label='Workspace Name' value={workspaceName} onChange={e => setworkspaceName(e.target.value)} fullWidth/>
                    </Grid>
                    <Grid item xs={12} >
                        {loading &&  <LinearProgress />}
                    </Grid>
                    <Grid item xs={12} className="inputRow">
                        <Button fullWidth variant="contained" onClick={() => handleAddNewWorkspace()} > Create </Button>
                    </Grid>
                </Grid>
            </Modal>

            <Button variant={'text'} onClick={() => setOpenModal(true)} >
                New Workspace
            </Button>
        
        </>
    )
}

export default CreateNewWorkspace;