import { Grid } from "@mui/material";
import AppLayout from "../../../layouts/Application";
import UsersList from "../../../components/team/ListUsers";

const WorkspaceManageTeamPage = () => {
    return (
        <AppLayout>
            <Grid container  >
                <Grid item xs={10} className="defaultSection">
                    <UsersList />
                </Grid>
                <Grid item xs={2}
                    alignItems={"center"} justifyContent={"center"} 
                >
                </Grid>
            </Grid>
        </AppLayout>       
    )
}


export default WorkspaceManageTeamPage;