import { Grid, Table, TableBody, TableCell, TableHead, TableRow, Typography } from "@mui/material"
import { gql, useQuery } from '@apollo/client';
import { useSelector } from "react-redux";
import { RootState } from "../../state/store";
import AddWorkspaceUser from "./AddWorkspaceUser";
import LoaderUsersList from "./skeletons/LoaderListUsers";


const GQL_QUERY = gql`
    query WorkspaceUsers($workspaceUsersId: ID!) {
        WorkspaceUsers(id: $workspaceUsersId) {
            phone
            id
            phoneCountryCode
            status
        }
    }   
`;

const UsersList = () => {
    const workspaceId = useSelector((state : RootState) => state.activeId.selectedWorkspace);

    const { loading, error, data, refetch} = useQuery(GQL_QUERY,{
        variables : {
            workspaceUsersId: workspaceId
        }
    });
  

    if (error) return  (<Typography> Error! {error.message}</Typography>);
  
  


    return (
        <Grid container>
            <Grid item xs={12} display={'flex'} justifyContent={'center'} style={{ paddingTop : 15 , paddingBottom : 15 }} >
                <Grid container>
                    <Grid item xs={8} display={'flex'} justifyContent={'flex-start'} >
                        <Typography variant='button'  textAlign={'center'}> Workspace Users </Typography>
                    </Grid>
                    <Grid item xs={4} display={'flex'} justifyContent={'flex-end'}>
                        <AddWorkspaceUser openbyDefault={false}  onSuccess={() => refetch()} />
                    </Grid>

                </Grid>
            </Grid>
            <Grid item xs={12}>
                <Table>
                    <TableHead>
                        <TableRow>
                            <TableCell> UserId </TableCell>
                            <TableCell> Phone </TableCell>
                            <TableCell> Status </TableCell>
                            <TableCell> Actions </TableCell>
                        </TableRow>
                    </TableHead>
                    <TableBody>
                        {loading && <LoaderUsersList />}
                        {!loading && data.WorkspaceUsers.map((row : any) => (
                            <TableRow key={row.id}>
                                <TableCell> {row.id} </TableCell>
                                <TableCell> {row.phone} </TableCell>
                                <TableCell> {row.status} </TableCell>
                                <TableCell> 
                                    {/* <Button variant="contained" color="primary" onClick={() => { dispatch(setActiveWorkspace(row.id)) }}> 
                                        Select
                                    </Button> */}
                                </TableCell>
                            </TableRow>
                        ))}
                    </TableBody>
                </Table>
            </Grid>

        </Grid>
    )
}

export default UsersList;