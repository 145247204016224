import { createSlice } from '@reduxjs/toolkit'

export interface ActiveIdsInState {
    selectedWorkspace : String
    selectedProject : String
    selectedTask : String
}

const initialState: ActiveIdsInState = {
  selectedWorkspace : '',
  selectedProject : '',
  selectedTask : ''
}

export const userLoggedInSlice = createSlice({
  name: 'user',
  initialState,
  reducers: {
    updateSelectedWorkspace: (state, action) => {
        state.selectedWorkspace = action.payload;
    },
    updateSelectedProject: (state, action) => {
        state.selectedProject = action.payload;
    },
    updateSelectedTask: (state, action) => {
        state.selectedTask = action.payload;
    }
  },
})

// Action creators are generated for each case reducer function
export const { updateSelectedProject,updateSelectedWorkspace,updateSelectedTask } = userLoggedInSlice.actions

export default userLoggedInSlice.reducer