import React from 'react';
import ReactDOM from 'react-dom/client';
import './styles/sitewide.scss';
import reportWebVitals from './reportWebVitals';
import {
  RouterProvider,
} from "react-router-dom";
import { ThemeProvider, createTheme } from "@mui/material/styles";
import CssBaseline from "@mui/material/CssBaseline";
import { store , persistor } from './state/store';
import { Provider, useSelector } from 'react-redux';
import type { RootState } from './state/store';
import { PersistGate } from 'redux-persist/integration/react';
import { ApolloClient, InMemoryCache, ApolloProvider, HttpLink, from } from '@apollo/client';
import router from './router/index';
import { onError } from "@apollo/client/link/error";
import { forceLogout } from './functions/auth';

const errorLink = onError((errors ) => {
  // const dispatch = useDispatch();
  errors?.graphQLErrors?.forEach(item => {
      if(item.extensions.code === "UNAUTHENTICATED"){
        forceLogout();
      }
  })
});

const root = ReactDOM.createRoot(
  document.getElementById('root') as HTMLElement
);

const additiveLink = from([
  errorLink,
  new HttpLink({ uri: '/graphql'})
]);

const client = new ApolloClient({
  link: additiveLink,
  credentials : 'same-origin',
  cache: new InMemoryCache(),
});

const App = () => {

  const isDarkModeEnabled = useSelector((state: RootState) => state.darkMode.isEnabled)

  // create a darkTheme function to handle dark theme using createTheme
  const darkTheme = createTheme({
    palette: {
      mode: isDarkModeEnabled ? 'dark' : 'light',
    },
  });


  return ( 
      <ThemeProvider theme={darkTheme}>
        <CssBaseline />
        <RouterProvider router={router} />
      </ThemeProvider>
  )
}


root.render(
  <React.StrictMode>
    <Provider store={store}>
      <PersistGate loading={null} persistor={persistor}>
        <ApolloProvider client={client}>
          <App />
        </ApolloProvider>
      </PersistGate>
    </Provider>
  </React.StrictMode>
);

// If you want to start measuring performance in your app, pass a function
// to log results (for example: reportWebVitals(console.log))
// or send to an analytics endpoint. Learn more: https://bit.ly/CRA-vitals
reportWebVitals();
