import { combineReducers, configureStore } from '@reduxjs/toolkit'
import { persistReducer, persistStore } from 'redux-persist';
import storage from 'redux-persist/lib/storage';
import darkModeReducer from './slices/toggleDarkMode'
import userLoggedIn from './slices/userLoggedIn';
import activeIds from './slices/activeIds';

const persistConfig = {
  key: 'root',
  storage,
}

const rootReducer = combineReducers({
  darkMode : darkModeReducer, 
  user : userLoggedIn,
  activeId : activeIds
})

const persistedReducer = persistReducer(persistConfig, rootReducer)

export const store = configureStore({
  reducer: persistedReducer,
  middleware: (getDefaultMiddleware) => getDefaultMiddleware({
    serializableCheck: false
  }),
  devTools: process.env.NODE_ENV !== 'production',
})

export const persistor = persistStore(store)

// Infer the `RootState` and `AppDispatch` types from the store itself
export type RootState = ReturnType<typeof store.getState>
// Inferred type: {posts: PostsState, comments: CommentsState, users: UsersState}
export type AppDispatch = typeof store.dispatch