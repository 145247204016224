import { Container, Grid } from "@mui/material";
import React from "react";
import HeaderBar from "../components/layout/Header";
import ApplicationSecondaryHeader from "../components/layout/ApplicationSecondaryHeader";
import ManageWorkspaceBar from "../components/workspace/ManageWorkspaceBar";



interface Props {
    children: any ;
}
  
const AppLayout: React.FC<Props> = ({ children }) => {
    return (
        <Container>
            <Grid>
                <HeaderBar />
            </Grid>
            <Grid>
                <ApplicationSecondaryHeader />
            </Grid>
            <Grid>
                <ManageWorkspaceBar />
            </Grid>
            <Grid>
                {children}
            </Grid>
        </Container>   
    )
}


export default AppLayout;